import React, { useEffect, useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Pod from "../components/pod"

const podcast = ({ data, location }) => {
  const shuffle = arr => arr.sort(() => Math.random() - 0.5)
  const siteTitle = data.site.siteMetadata.title
  const podcasts = shuffle(data.site.siteMetadata.podcasts)

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Podcast" />
      {podcasts.map(url => (
        <Pod url={url} />
      ))}
    </Layout>
  )
}

export default podcast

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        podcasts
      }
    }
  }
`
