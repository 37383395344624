import React, { useEffect } from "react"

const Pod = ({ url }) => {
  return (
    <div className="relative h-0 pb-48 my-6 md:flex-1 mx-2">
      <iframe
        id={url}
        className="absolute w-full h-full top-0 left-0 border-black"
        src={url}
        frameBorder="0"
        scrolling="no"
        loading="lazy"
        allowFullScreen
      />
    </div>
  )
}

export default Pod
